<template>
  <div class="TalentRecruitmentList">
    <!-- <el-page-header @back="backPrivious" content="职位列表"></el-page-header>
    <div class="list">
      <PositionItem v-for="(item, index) of list" :Jobitem="item" :key="index"></PositionItem>
    </div>
    <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout" @pagination="fetchData" /> -->
    <div class="head f-c-b">
      <div class="LeftPart f-c">
        <img class="logo" src="@/assets/Community/logo.png" alt="">
        <img class="shiliaologo" src="@/assets/Community/shiliaologo.png" alt="">
      </div>
      <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">
          <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
          <span> 当前位置： 首页</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/CommunityIndex' }">社区</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/TalentRecruitmentIndex' }">人才招聘</el-breadcrumb-item>
        <el-breadcrumb-item>岗位招聘</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main f-cl-c-c">
      <div class="searchBox flex">
        <input type="text" v-model.trim="positionSearch" @keyup.enter="search" />
        <img @click="search" src="@/assets/Community/search.png" alt="">
      </div>
      <div class="list">
        <div class="item f-cl-b" v-for="(item, index) of list" :key="index" @click="toJobdetail(item.postId)">
          <div class="f-c-b">
            <span class="jobtitle">{{ item.position }}</span>
            <span class="salary-range">职位月薪：{{ item.salary }}</span>
          </div>
          <div class="w--100 f-c mt-20">
              <img class="eimage1" :src="item.eimage1" alt="eimage1">
              <div class="enterpriseName">{{ item.enterpriseName }}</div>
          </div>
          <!-- <span class="enterpriseName">{{ item.enterpriseName }}</span> -->
        </div>
      </div>
    </div>
    <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout" @pagination="fetchData" />
  </div>
</template>

<script>
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
export default {
  name: 'TalentRecruitmentList',
  mixins: [PaginationRetention],
  // components: {
  //   PositionItem: () => import('./components/PositionItem.vue')
  // },
  data() {
    return {
      list: [],
      positionSearch:''
    }
  },
  created() { },
  methods: {
    getDate() {
      this.forumRecruitArticleList(this.paging, this.pagingSize)
    },
    // 获取参数
    getParams(page, size) {
      let that = this;
      return {
        postUserId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token'),
        positionSearch:that.positionSearch,
        enterpriseId: '',
        page,
        size
      }
    },
    // 职位招聘帖子列表接口
    forumRecruitArticleList(page = this.page, size = this.size) {
      let that = this;
      that.$http.forumRecruitArticleList(that.getParams(page, size)).then(res => {
        if (res.code == 200) {
          that.list = res.data.records
          that.currentPage = res.data.current;//当前页数
          that.total = res.data.total
        }
      })
    },
    toJobdetail(data){
      this.$router.push({
          path: '/TalentRecruitmentDetail',
          query: {
            key: this.$UrlEncode.encode(JSON.stringify({
              type: 'detail',
              id: data
            }))
          }
        })
    },
    search(){
        this.forumRecruitArticleList(1, 10)
      }

  },
}

</script>
<style lang='less' scoped>
.TalentRecruitmentList {
  .head {
    padding: 20px 0;
    border-bottom: 1px solid #F7F7F8;

    .LeftPart {
      width: 55px;
      cursor: pointer;

      .logo {
        width: 53px;
        height: 53px;
        margin-right: 18px;
      }

      .shiliaologo {
        width: 77px;
        height: 38px;
      }

    }

    .el-breadcrumb ::v-deep .el-breadcrumb__inner {
      color: #666666 !important;
      font-weight: 400 !important;
      font-size: 14px;
    }

    .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
      color: #0363FA !important;
      font-weight: 400 !important;
      font-size: 14px;
    }
  }

  .main {
    .searchBox {
      width: 790px;
      height: 60px;
      // border: 1px solid #0363FA;

      input {
        background-color: #FFF;
        border-radius: 4px 0 0 4px;
        border: 1px solid #E0E1E3;
        border-right: none;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        height: 60px;
        line-height: 60px;
        outline: 0;
        padding: 10px;
        transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
        width: 660px;
        font-size: 24px;
      }

      img {
        cursor: pointer;
      }
    }

    .list {
      margin-top: 35px;
      width: 790px;

      .item {
        cursor: pointer;
        height: 100px;
        border-radius: 20px;
        border: 1px solid #C4C4C4;
        margin-bottom: 30px;
        padding: 30px 50px;

        .jobtitle {
          font-size: 24px;
          font-family: HarmonyOS Sans SC;
          font-weight: 400;
          color: #333333;
        }

        .salary-range {
          font-size: 16px;
          font-family: HarmonyOS Sans SC;
          font-weight: 400;
          color: #666666;
        }
        .eimage1{
            border-radius: 50%;
            width: 40px;
            height: 40px;
        }

        .enterpriseName {
          margin-left: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}
</style>
